<template>
  <div class="home-list">
    <div style="display:flex;margin-top:10px;">
      <!-- 最新文章 -->
      <div>
        <div
          v-infinite-scroll="loadMore"
          infinite-scroll-disabled="busy"
          infinite-scroll-distance="400"
          style="width: 755px;"
        >
          <div
            class="con-item"
            v-for="item in data"
            :key="item.id"
            @click="toDetail(item)"
          >
            <div class="bg-img-box">
              <div
                :style="{
                  backgroundImage: 'url(' + picHead + item.poster + ')',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  height: '145px',
                  width: '245px',
                  backgroundPosition: 'center center',
                }"
                class="bg-img"
              >
                <!-- mediaType 文章的类型，0为默认，1表示为视频文章 -->
                <div class="play-icon-new" v-if="item.mediaType == 1">
                  <i class="el-icon-caret-right"></i>
                </div>
              </div>
            </div>
            <div class="con-item-right">
              <div class="con-text">
                <div class="texts">
                  {{ item.title }}
                </div>
              </div>
              <div class="tips-con">
                <div class="date">
                  <span>{{ item.originAuthor}}</span>
                  <span class="ml-10">{{ item.createDate | dateFormate }}</span>
                </div>
                <div class="tips">
                  <span v-if="item.commentNum">{{ item.commentNum }}评论</span>
                  <span v-if="item.thumbsNum">{{ item.thumbsNum }}赞</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="loadMore" v-if="droping">加载中...</div>
        <div id="loadMore" v-else-if="!droping && nodata">没有更多啦～</div>
        <div class="emptyText" v-else-if="isEmpty">没有更多啦～</div>
      </div>
      <!-- 最新推荐 -->
      <div class="recom-box">
        <div class="title">最新推荐</div>
        <div
            class="recom-item"
            v-for="item in recomData"
            :key="item.id"
            @click="toDetail(item)"
          >
            <div class="bg-img-box">
              <div
                :style="{
                  backgroundImage: 'url(' + picHead + item.poster + ')',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  height: '145px',
                  backgroundPosition: 'center center'
                }"
                class="bg-img"
              >
                <!-- mediaType 文章的类型，0为默认，1表示为视频文章 -->
                <div class="play-icon-new" v-if="item.mediaType == 1"><i class="el-icon-caret-right"></i></div>
              </div>
            </div>
            <div class="con-text">
              <div class="texts">
                {{ item.title }}
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isEmpty: false,
      data: [],
      busy: false,
      articlesParams: {
        pageNo: 1,
        pageSize: 20,
        queryType: 1, // 1,分页查询;2,list查询;3,首页推荐查询;
        navTypeId: '', // 搜索条件(文章分类)
      },
      nodata: false,
      droping: false,

      recomData: [], // 最新推荐
    };
  },
  computed: {
    picHead() {
      return this.$store.state.picHead;
    },
  },
  mounted() {
    this.articlesParams.navTypeId = this.$route.query.navTypeId;

    // 最新推荐
    this.getRecomList()
  },
  methods: {
    getRecomList() {
      this.$request(this.$api.getArticles, { queryType: 2, navTypeId: this.articlesParams.navTypeId, recomFlag: true}).then(res=> {
        if (res.success) {
          this.recomData = res.datas
        }
      })
    },
    toDetail(val) {
      let routeData = this.$router.resolve({
        name: 'Detail',
        params: {
          id: val.id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    loadMore() {
      this.busy = true;
      this.droping = true; //加载中
      this.$request(this.$api.getArticles, this.articlesParams)
        .then((res) => {
          this.droping = false; //加载中
          if (res.success) {
            // 第一页数据为空
            if (
              res.datas.datas.length == 0 &&
              this.articlesParams.pageNo == 1
            ) {
              this.isEmpty = true;
              return false;
            }
            if (this.articlesParams.pageNo > res.datas.totalPage) return false;
            if (!!this.$route.query.navTypeId) {
              this.data = this.data.concat(res.datas.datas);
            } else {
              if (this.articlesParams.pageNo == 1) {
                this.data = res.datas.datas.splice(2, res.datas.datas.length);
                this.$parent.zhiDingData = res.datas.datas;
              } else {
                this.data = this.data.concat(res.datas.datas);
              }
            }
            if (!!this.$route.query.navTypeId) {
              if (this.data.length == res.datas.totalCount) {
                this.nodata = true;
              } else {
                this.nodata = false;
              }
            } else {
              if (this.data.length == res.datas.totalCount * 1 - 2) {
                this.nodata = true;
              } else {
                this.nodata = false;
              }
            }

            this.articlesParams.pageNo++;
            this.busy = false;
          }
        })
        .catch((e) => {
          this.busy = false;
          this.showLoading = false;
          console.log(e);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.home-list {
  .con-item {
    display: flex;
    width: 755px;
    background: white;
    margin-bottom: 10px;
    overflow: hidden;
    .con-item-right {
      flex: 1;
      padding: 10px 10px 20px 10px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }
  .recom-box {
    margin-left: 10px;
    background: #fff;
    height: fit-content;
    .title {
      height: 20px;
      padding: 10px 10px 0 10px;
      color: #101010;
      font-weight: bold;
    }
    .recom-item {
      background: white;
      margin: 10px;
      .con-text {
        padding-top: 10px;
        .texts {
          overflow: hidden;
          height: 44px;
          font-weight: 400;
          color: #0f1419;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}
.bg-img-box {
  overflow: hidden;
  .bg-img {
    position: relative;
    text-align: center;
    transition: transform 0.6s ease-in-out;
    cursor: pointer;
    .icon {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      margin-top: -25px;
      left: 50%;
      margin-left: -25px;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
}

.con-text {
  .texts {
    overflow: hidden;
    font-weight: 400;
    color: #0f1419;
    font-size: 16px;
    line-height: 22px;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.tips-con {
  display: flex;
  bottom: 0;
  left: 0;
  color: #666;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  .date {
    float: left;
  }
  .tips {
    float: right;
  }
}
#loadMore {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #101010;
  font-size: 14px;
}
.emptyText {
  color: #101010;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 273px);
  background: white;
}
</style>
