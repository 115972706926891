<template>
  <div>
    <div class="home-con" v-if="!articlesParams.navTypeId">
      <div class="carousel">
        <Banner :bannerList="bannerList" @toList="toList" />
      </div>
      <div class="top">
        <div
          class="box-item"
          v-for="(item, index) in zhiDingData"
          :key="index"
          v-if="index < 2"
          :style="{
            backgroundImage: 'url(' + picHead + item.poster + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }"
          @click="toDetail(item)"
        >
          <div class="item-info">
            <div class="item-title">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 1 列表样式模版 -->
    <HomeList v-if="this.$store.state.webTemplate == 1" />
    <!-- 0 方格样式模版 -->
    <HomeSquare v-if="this.$store.state.webTemplate == 0 || !this.$store.state.webTemplate" />
  </div>
</template>

<script>
import Banner from '../component/Banner';
import HomeList from './HomeList';
import HomeSquare from './HomeSquare';
export default {
  inject: ['reload'],
  components: { Banner, HomeList, HomeSquare },
  data() {
    return {
      zhiDingData: [],
      articlesParams: {
        pageNo: 1,
        pageSize: 20,
        queryType: 1, // 1,分页查询;2,list查询;3,首页推荐查询;
        navTypeId: '', // 搜索条件(文章分类)
      },
      bannerList: [],
    };
  },
  watch: {
    $route(to, from) {
      console.log('路由变化');
      this.reload();
    },
  },
  computed: {
    picHead() {
      return this.$store.state.picHead;
    },
  },
  mounted() {
    this.articlesParams.navTypeId = this.$route.query.navTypeId;
    this.getInit();
  },
  methods: {
    toList(navtypeId) {
      let index = this.$parent.navTypesData.findIndex((obj, index, arr) => {
        return obj.id == navtypeId;
      });
      let routeData = this.$router.resolve({
        query: {
          index: index,
          navTypeId: navtypeId,
        },
      });
      window.open(routeData.href, '_blank');
    },
    getInit() {
      // 获取banner图 linkType: 2
      this.$request(this.$api.getPictures, { type: 0 }).then((res) => {
        if (res.success) {
          this.bannerList = res.datas;
        }
      });
    },
    toDetail(val) {
      let routeData = this.$router.resolve({
        name: 'Detail',
        params: {
          id: val.id,
        },
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.home-con {
  display: flex;
  .carousel {
    width: 755px;

    .item {
      height: 29px;
      line-height: 29px;
      color: rgba(255, 255, 255, 100);
      font-size: 20px;
      font-family: SourceHanSansSC-regula;
      width: auto;
      display: inline-flex;
      padding: 0 5px;
      position: absolute;
      left: 20px;
      bottom: 0px;
    }
  }
  .top {
    width: 255px;
    .box-item {
      margin-left: 10px;
      margin-bottom: 10px;
      height: calc(50% - 5px);
      position: relative;
      cursor: pointer;
      .item-title {
        position: absolute;
        bottom: 10px;
      }
    }
  }
}
.home-content {
  display: flex;
  padding-top: 10px;
  .con-item {
    // width: calc(25% - 5px);
    width: 245px;
    background: white;
    cursor: pointer;
    overflow: hidden;
    &:nth-child(4n + 1) {
      // background: blue;
      margin-right: 5px;
    }
    &:nth-child(4n + 2) {
      // background: pink;
      margin: 0 5px;
    }
    &:nth-child(4n + 3) {
      // background: yellow;
      margin: 0 5px;
    }
    &:nth-child(4n + 4) {
      // background: blue;
      margin-left: 5px;
    }
    .bg-img {
      position: relative;
      text-align: center;
      transition: transform 0.6s ease-in-out;
      .icon {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        margin-top: -25px;
        left: 50%;
        margin-left: -25px;
      }
    }
    .bg-img:hover {
      transform: scale(1.05);
    }
    .con-text {
      padding: 12px 10px 0px;
      .texts {
        overflow: hidden;
        height: 44px;
        font-weight: 400;
        color: #0f1419;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
.tips-con {
  margin-top: 10px;
  display: flex;
  bottom: 0;
  left: 0;
  padding: 0 10px 10px;
  color: #666;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  .date {
    float: left;
  }
  .tips {
    float: right;
  }
}

.item-title {
  padding: 12px 10px 0px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.item-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    -180deg,
    hsla(0, 0%, 74.9%, 0),
    rgba(0, 0, 0, 0.78) 97%
  );
  transition: all 0.5s;
}
#loadMore {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #101010;
  font-size: 14px;
}
.emptyText {
  color: #101010;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 273px);
  background: white;
}
</style>
