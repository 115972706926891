<template>
  <div>
    <div
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="400"
    >
      <div
        class="home-content"
        v-for="(item, index) in pullDownArticlesData"
        :key="index"
      >
        <div
          class="con-item"
          v-for="cItem in item"
          :key="cItem.id"
          @click="toDetail(cItem)"
        >
          <div
            :style="{
              backgroundImage: 'url(' + picHead + cItem.poster + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              height: '145px',
              backgroundPosition: 'center center'
            }"
            class="bg-img"
          >
            <!-- mediaType 文章的类型，0为默认，1表示为视频文章 -->
            <div class="play-icon-new" v-if="cItem.mediaType == 1"><i class="el-icon-caret-right"></i></div>
          </div>
          <div class="con-text">
            <div class="texts">
              {{ cItem.title }}
            </div>
          </div>
          <div class="tips-con">
            <div class="date">
              <span>{{ cItem.createDate | dateFormate }}</span>
            </div>
            <div class="tips">
              <span v-if="cItem.commentNum">{{ cItem.commentNum }}评论</span>
              <span v-if="cItem.thumbsNum">{{ cItem.thumbsNum }}赞</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="loadMore" v-if="droping">加载中...</div>
    <div id="loadMore" v-else-if="!droping && nodata">没有更多啦～</div>
    <div class="emptyText" v-else-if="isEmpty">
     没有更多啦～
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isEmpty: false,
      pullDownArticlesData: [],
      data: [],
      busy: false,
      articlesParams: {
        pageNo: 1,
        pageSize: 20,
        queryType: 1, // 1,分页查询;2,list查询;3,首页推荐查询;
        navTypeId: '', // 搜索条件(文章分类)
      },
      nodata: false,
      droping: false,
    };
  },
  computed: {
    picHead() {
      return this.$store.state.picHead;
    },
  },
  mounted() {
    this.articlesParams.navTypeId = this.$route.query.navTypeId;
  },
  methods: {
    toDetail(val) {
      let routeData = this.$router.resolve({
        name: 'Detail',
        params: {
          id: val.id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    loadMore() {
      this.busy = true;
      this.droping = true; //加载中
      this.$request(this.$api.getArticles, this.articlesParams)
        .then((res) => {
          this.droping = false; //加载中
          if (res.success) {
            // 第一页数据为空
            if (
              res.datas.datas.length == 0 &&
              this.articlesParams.pageNo == 1
            ) {
              this.isEmpty = true;
              return false;
            }
            if (this.articlesParams.pageNo > res.datas.totalPage) return false;
            if (!!this.$route.query.navTypeId) {
              this.data = this.data.concat(res.datas.datas);
            } else {
              if(this.articlesParams.pageNo == 1) {
                this.data = res.datas.datas.splice(2, res.datas.datas.length);
                this.$parent.zhiDingData =res.datas.datas
              } else {
                this.data = this.data.concat(res.datas.datas);
              }
            }
            if (!!this.$route.query.navTypeId) {
              if (this.data.length == res.datas.totalCount) {
                this.nodata = true;
              } else {
                this.nodata = false;
              }
            } else {
              if (this.data.length == (res.datas.totalCount * 1 - 2)) {
                this.nodata = true;
              } else {
                this.nodata = false;
              }
            }

            this.articlesParams.pageNo++;
            this.busy = false;

            this.pullDownArticlesData = this.data.reduce(
              (pre, next, idx) => {
                // reduce 用来便利数组，具体语法就 rtfm 吧
                const inner = pre[~~(idx / 4)]; // ~~用来取整，inner 是内层数组
                if (inner !== undefined) {
                  // 判断有没有内层数组
                  inner.push(next); // 如果有就把遍历的值 next push 到内层数组里
                } else {
                  pre.push([next]); // 没有就新建一个包含 next 的数组，作为内层数组
                }
                return pre;
              },
              [[]]
            );
            console.log('getServer', this.pullDownArticlesData);
          }
        })
        .catch((e) => {
          this.busy = false;
          this.showLoading = false;
          console.log(e);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.home-con {
  display: flex;
  .carousel {
    width: 755px;

    .item {
      height: 29px;
      line-height: 29px;
      color: rgba(255, 255, 255, 100);
      font-size: 20px;
      font-family: SourceHanSansSC-regula;
      width: auto;
      display: inline-flex;
      padding: 0 5px;
      position: absolute;
      left: 20px;
      bottom: 0px;
    }
  }
  .top {
    width: 255px;
    .box-item {
      margin-left: 10px;
      margin-bottom: 10px;
      height: calc(50% - 5px);
      position: relative;
      cursor: pointer;
      .item-title {
        position: absolute;
        bottom: 10px;
      }
    }
  }
}
.home-content {
  display: flex;
  padding-top: 10px;
  .con-item {
    // width: calc(25% - 5px);
    width: 245px;
    background: white;
    cursor: pointer;
    overflow: hidden;
    &:nth-child(4n + 1) {
      // background: blue;
      margin-right: 5px;
    }
    &:nth-child(4n + 2) {
      // background: pink;
      margin: 0 5px;
    }
    &:nth-child(4n + 3) {
      // background: yellow;
      margin: 0 5px;
    }
    &:nth-child(4n + 4) {
      // background: blue;
      margin-left: 5px;
    }
    .bg-img {
      position: relative;
      text-align: center;
      transition: transform 0.6s ease-in-out;
      .icon {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        margin-top: -25px;
        left: 50%;
        margin-left: -25px;
      }
    }
    .bg-img:hover {
      transform: scale(1.05);
    }
    .con-text {
      padding: 12px 10px 0px;
      .texts {
        overflow: hidden;
        height: 44px;
        font-weight: 400;
        color: #0f1419;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
.tips-con {
  margin-top: 10px;
  display: flex;
  bottom: 0;
  left: 0;
  padding: 0 10px 10px;
  color: #666;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  .date {
    float: left;
  }
  .tips {
    float: right;
  }
}

.item-title {
  padding: 12px 10px 0px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.item-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    -180deg,
    hsla(0, 0%, 74.9%, 0),
    rgba(0, 0, 0, 0.78) 97%
  );
  transition: all 0.5s;
}
#loadMore {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #101010;
  font-size: 14px;
}
.emptyText {
  color: #101010;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 273px);
  background: white;
}
</style>
