var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-list"},[_c('div',{staticStyle:{"display":"flex","margin-top":"10px"}},[_c('div',[_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],staticStyle:{"width":"755px"},attrs:{"infinite-scroll-disabled":"busy","infinite-scroll-distance":"400"}},_vm._l((_vm.data),function(item){return _c('div',{key:item.id,staticClass:"con-item",on:{"click":function($event){return _vm.toDetail(item)}}},[_c('div',{staticClass:"bg-img-box"},[_c('div',{staticClass:"bg-img",style:({
                backgroundImage: 'url(' + _vm.picHead + item.poster + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: '145px',
                width: '245px',
                backgroundPosition: 'center center',
              })},[(item.mediaType == 1)?_c('div',{staticClass:"play-icon-new"},[_c('i',{staticClass:"el-icon-caret-right"})]):_vm._e()])]),_c('div',{staticClass:"con-item-right"},[_c('div',{staticClass:"con-text"},[_c('div',{staticClass:"texts"},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('div',{staticClass:"tips-con"},[_c('div',{staticClass:"date"},[_c('span',[_vm._v(_vm._s(item.originAuthor))]),_c('span',{staticClass:"ml-10"},[_vm._v(_vm._s(_vm._f("dateFormate")(item.createDate)))])]),_c('div',{staticClass:"tips"},[(item.commentNum)?_c('span',[_vm._v(_vm._s(item.commentNum)+"评论")]):_vm._e(),(item.thumbsNum)?_c('span',[_vm._v(_vm._s(item.thumbsNum)+"赞")]):_vm._e()])])])])}),0),(_vm.droping)?_c('div',{attrs:{"id":"loadMore"}},[_vm._v("加载中...")]):(!_vm.droping && _vm.nodata)?_c('div',{attrs:{"id":"loadMore"}},[_vm._v("没有更多啦～")]):(_vm.isEmpty)?_c('div',{staticClass:"emptyText"},[_vm._v("没有更多啦～")]):_vm._e()]),_c('div',{staticClass:"recom-box"},[_c('div',{staticClass:"title"},[_vm._v("最新推荐")]),_vm._l((_vm.recomData),function(item){return _c('div',{key:item.id,staticClass:"recom-item",on:{"click":function($event){return _vm.toDetail(item)}}},[_c('div',{staticClass:"bg-img-box"},[_c('div',{staticClass:"bg-img",style:({
                backgroundImage: 'url(' + _vm.picHead + item.poster + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: '145px',
                backgroundPosition: 'center center'
              })},[(item.mediaType == 1)?_c('div',{staticClass:"play-icon-new"},[_c('i',{staticClass:"el-icon-caret-right"})]):_vm._e()])]),_c('div',{staticClass:"con-text"},[_c('div',{staticClass:"texts"},[_vm._v(" "+_vm._s(item.title)+" ")])])])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }